<template>
  <section class="main_container">
    <div class="col align-center text-center">
      <h2>Get started with SecurTeam</h2>
      <p class="font-20">14-day Free Trial, then 3.99€ / month / user*</p>
      <p class="font-20">Add/Remove users at will. Stop anytime.</p>
      <form @submit.prevent="payNow" class="formContainer col gap-20">
        <div class="text-start">
          <input
            v-model="name"
            placeholder="Your Name"
            type="text"
            class="input"
            :class="{ error: nameError }"
          />
          <span class="red--text font-14" v-if="nameError">
            {{ nameError }}
          </span>
        </div>
        <div class="text-start">
          <input
            v-model="email"
            placeholder="Your Email"
            type="text"
            class="input"
            :class="{ error: emailError }"
          />
          <span class="red--text font-14" v-if="emailError">
            {{ emailError }}
          </span>
        </div>
        <div class="text-start">
          <input
            v-model="team"
            placeholder="Company or Team Name"
            type="text"
            class="input"
            :class="{ error: team_nameError }"
          />
          <span class="red--text font-14" v-if="team_nameError">
            {{ team_nameError }}
          </span>
        </div>
        <div class="text-start">
          <input
            placeholder=" (Optional) Domain Name to Access SecurTeam"
            type="text"
            class="input"
            v-model="domain"
            :class="{ error: domain_nameError }"
          />
          <span class="red--text font-14" v-if="domain_nameError">
            {{ domain_nameError }}
          </span>
        </div>
        <p class="font-20 text-center">
          By signing up, you agree to our
          <router-link to="/terms-of-service">Terms</router-link>
        </p>
        <button class="btn">PAY NOW</button>
        <p class="text-center font-14">
          * You will be charged $3.99 now, and for any additional users at the
          end of the month.
        </p>
      </form>
    </div>
    <div class="gap-50 pa-50" :class="$screen.md ? 'row' : 'col'">
      <div class="grow center">
        <img src="/img/stripe.png" class="w100" alt="" />
      </div>
      <div class="grow center">
        <img src="/img/complient.png" alt="" class="w100" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      email: "",
      team: "",
      domain: "",
      nameError: "",
      emailError: "",
      domain_nameError: "",
      team_nameError: "",
    };
  },
  methods: {
    validateForm() {
      let required = "Required";
      let nameError =
        "Only alphanumeric characters, underscore, dot and space allowed, min length 3, max length 63";
      let emailError = "Not valid email address";
      let team_nameError =
        "Only alphanumeric characters, underscore and dot allowed, min length 3, max length 63";
      let domain_nameError =
        "Only alphanumeric characters, underscore and dot allowed, min length 3, max length 63";

      let reg = new RegExp("^[a-zA-Z0-9_. ]{3,63}$");
      let name = reg.test(this.name);
      reg = RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      let email = reg.test(this.email);
      reg = new RegExp("^[a-zA-Z0-9_.]{3,63}$");
      let domain = reg.test(this.domain);
      let team = reg.test(this.team);

      this.nameError = !name ? nameError : "";
      this.emailError = !email ? emailError : "";
      this.domain_nameError = !domain ? domain_nameError : "";
      this.team_nameError = !team ? team_nameError : "";

      if (this.name.length == 0) this.nameError = required;
      if (this.email.length == 0) this.emailError = required;
      if (this.domain.length == 0) this.domain_nameError = "";
      if (this.team.length == 0) this.team_nameError = required;

      return (
        this.nameError ||
        this.emailError ||
        this.domain_nameError ||
        this.team_nameError
      );
    },
    async checkTeamName() {
      let url = "https://www.securteam.com/check_team_name.php";
      let res = await fetch(url, {
        method: "POST",
        body: JSON.stringify({ team_name: this.team }),
      });
      res = await res.json();
      console.log(res);
    },
    async addClient() {
      let url = "https://www.securteam.com/addclient.php";
      let res = await fetch(url, {
        method: "POST",
        body: JSON.stringify({ team_name: "TestName" }),
      });
      res = await res.json();
      console.log(res);
    },
    payNow() {
      this.checkTeamName();
      if (this.validateForm()) return;
    },
  },
  mounted() {},
};
</script>

<style scoped>
.input {
  border: 1px solid rgb(147, 147, 147);
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  padding: 15px;
  font-size: 20px;
  box-sizing: border-box;
}

.formContainer {
  max-width: 600px;
  width: 100%;
}

img {
  max-width: 250px;
}

.error {
  background-color: rgb(251, 236, 236);
  border: 1px solid red;
}
</style>