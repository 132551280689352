<template>
  <div class="">
    <Signup />
  </div>
</template>

<script>
// @ is an alias to /src
import Signup from "@/components/signup/Signup.vue";

export default {
  name: "Home",
  components: {
    Signup,
  },
};
</script>
